import { MapService } from "services/map-service";
import {autoinject, bindable, observable, bindingMode} from "aurelia-framework";

@autoinject
export default class App {
  viewMode = 'intro';
  public password : string = '';
  realpassword = 'tequiero!';
  public attempts = 0;
  public currentQuestion : Question;
  public map : HTMLElement;
  @observable scoreValue = 0;

  public questions : Array<Question> = [
    
    new Question("¿En qué mes nos hicimos pareja?", "enero", "junio", "julio"),
    new Question("¿Cual es el primer pueblo que visitamos cuando llegamos a Bélgica?", "Achouffe"),
    new Question("¿Cual es el nombre del pueblo donde crecí?", "Lummen"),
    new Question("¿Como se dice \"Te quiero\" en Flamenco?", "Ik hou van u", "Ik hou van jou"),
    new Question("¿Que pelí vimos cuando me hice con la idea de hacer esta jincana?", "da vinci code", "el codigo da vinci", "codigo da vinci", "código da vinci", "el código de vince"),
    new Question("¿Como se llama la vía de largos que hicimos en Sa Gubia?", "ley del deseo", "la ley del deseo"),
    new Question("¿Quien es el campeon del mundo en ajedrez?", "Magnus Carlsen"),
    new Question("¿Como se llama el pueblo donde vivía con Maarten y Jurgen?", "Paal"),
    new Question("¿Como se llama la vía de escalada más dura en el mundo?", "Silence"),
    new Question("Cual es mi nombre completo?", "Kenneth Carlo Jean-Marie Marleen Truyers", "Kenneth Carlo JeanMarie Marleen Truyers"),
    new Question("¿En que año nació Yucca?", "2012"),
    new Question("¿En que año llegue a españa?", "2009"),
    new Question("¿Como se llama la empresa quie construyó la casa?", "Pietra"),
    new Question("¿En que año nació mi padre?", "1957"),
    new Question("From the Kentucky ________  to the California sun, yeah, Bobby shared the secrets of my soul", "coalmines", "coalmine"),
    new Question("¿Como se llama la empresa donde trabajaba con Paco?", "Northgate", "Northgate-Arinso", "Northgate Arinso"),
    new Question("¿Como se llama la empresa que tenía con Jurgen para los espectaculos?", "Circus Paljasso"),
    new Question("¿En que pueblo vive mi tía (le hermana de mi padre?", "Zoersel"),
    new Question("¿Como se llama mi abuela?", "Rachel"),
    new Question("¿Como se llama la banda en que tocaba?", "ne t'en fais pas")
  ];

  circles = [
    // 48.803899, 11.891056
    {lat: 48.803899, lng: 2.69601, radius: null, zoom: 1, map: 'street'},
    {lat: 48.803899, lng: 11.891056, radius: 3500000, zoom: 2, map: 'street'},
    {lat: 48.803899, lng: 11.891056, radius: 3000000, zoom: 3, map: 'street'},
    {lat: 48.803899, lng: 11.891056, radius: 2000000, zoom: 4, map: 'street'},
    // 40.459740, -3.141711
    {lat: 40.459740, lng: -3.141711, radius: 1000000, zoom: 5, map: 'street'},
    {lat: 40.459740, lng: -3.141711, radius: 750000, zoom: 6, map: 'street'},
    // 39.631681, 2.974516
    {lat: 39.631681, lng: 2.974516, radius: 100000, zoom: 8, map: 'street'},
    {lat: 39.631681, lng: 2.974516, radius: 50000, zoom: 9, map: 'street'},
    // 39.586192, 2.648295
    {lat: 39.586192, lng: 2.648295, radius: 10000, zoom: 11, map: 'street'},
    {lat: 39.604955, lng: 2.689567, radius: 5000, zoom: 13, map: 'satelite'},    
    {lat: 39.604955, lng: 2.689567, radius: 2000, zoom: 14, map: 'satelite'},
    {lat: 39.604955, lng: 2.689567, radius: 500, zoom: 15, map: 'satelite'},
    {lat: 39.606277, lng: 2.693322, radius: 250, zoom: 16, map: 'satelite'},
    {lat: 39.606277, lng: 2.693322, radius: 150, zoom: 17, map: 'satelite'},
    {lat: 39.606277, lng: 2.693322, radius: 30, zoom: 18, map: 'satelite'},
    {lat: 39.606277, lng: 2.693322, radius: 1, zoom: 25, map: 'satelite'}
  ];

  
  constructor(private mapService : MapService) {
    
  }

  enter(){
    if(this.password != this.realpassword){
      this.attempts += 1;
      this.viewMode = 'cheat' + this.attempts;
    } else{
      this.viewMode = 'testintro';
    }
    this.currentQuestion = this.questions[0];
  }

  startTest(){
    this.viewMode = 'test';
    this.mapService.initialize(this.map);
  }

  nextQuestion(){
    let currentIndex = this.questions.indexOf(this.currentQuestion);
    currentIndex += 1;
    if(currentIndex < 20)
    this.currentQuestion = this.questions[currentIndex];
  }

  previousQuestion(){
    let currentIndex = this.questions.indexOf(this.currentQuestion);
    currentIndex -= 1;
    if(currentIndex > 0)
    this.currentQuestion = this.questions[currentIndex];
  }

  get score(){
    this.scoreValue = this.questions.filter(q => q.isCorrect && q.isAnswered).length;
    return this.scoreValue + '/20';
  }

  scoreValueChanged(){
    if(this.mapService && this.circles && this.scoreValue < this.circles.length)
      this.mapService.setCurrentCircle(this.circles[this.scoreValue]);
  }
  
}

class Question{
  question: string;
  answers: string[];
  givenAnswer: string;
  
  constructor(question, ...answers : string[]) {
    this.question = question;
    this.answers = answers;
    this.givenAnswer = "";
    
  }
  get isCorrect(){
    return this.answers.some(a => a.toLowerCase() === this.givenAnswer.toLowerCase())
  }

  get isAnswered(){
    return this.givenAnswer !== "";
  }

  get state(){
    return !this.isAnswered ? 'amber' : 
            this.isCorrect ? 'green'
             : 'red';
  }
}
