export class MapService{
    platform : any;
    circle : any;
    map : any;
    defaultLayers : any;
    
    initialize(element){
        // @ts-ignore
        this.platform = new H.service.Platform({
            'apikey': 'zUg5vaPX7muI80soHD1Jo2M1u2mf1hgnVVutygeyg_Q'
          });

        // Obtain the default map types from the platform object:
        this.defaultLayers = this.platform.createDefaultLayers();

        // Instantiate (and display) a map object:
        // @ts-ignore
        this.map = new H.Map(
            element,
            this.defaultLayers.vector.normal.map,
            {
              zoom: 2,
              center: { lat: 39.60596, lng: 2.69601 }
            });
    }

    setCurrentCircle(circle : {lat : number, lng : number, radius? : number, zoom : number, map : string}){
        if(this.circle){
            this.map.removeObject(this.circle);
        }
        const center = {lat: circle.lat, lng: circle.lng};
        // Instantiate a circle object (using the default style):
        
        if(circle.radius){
          // @ts-ignore;
          this.circle = new H.map.Circle(center, circle.radius);
          this.map.addObject(this.circle);
        }
          
        this.map.setZoom(circle.zoom);
        this.map.setCenter(center);
        if(circle.map == 'street')
          this.map.setBaseLayer(this.defaultLayers.vector.normal.map);  
        else
          this.map.setBaseLayer(this.defaultLayers.raster.satellite.map);
    }
}